"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LikeModule {
    constructor(wpApiSettings, postIds, postTypesToShow, postAppearance, renderContainer, noPostsNotice, preLoaders) {
        this.wpApiSettings = wpApiSettings;
        this.postIds = postIds;
        this.postTypesToShow = postTypesToShow;
        this.postAppearance = postAppearance;
        this.renderContainer = renderContainer;
        this.noPostsNotice = noPostsNotice;
        this.preLoaders = preLoaders;
        this.displayNoneClass = 'u-display--none';
        this.handleLikedPosts();
    }
    handleLikedPosts() {
        if (!this.wpApiSettings) {
            console.error('wpApiSettings not found.');
            this.noPostsFound();
            return;
        }
        this.fetchPosts();
    }
    fetchPosts() {
        fetch(`${this.wpApiSettings.root}like/v1/ids=${this.getFilteredPostIds()}?html&appearance=${this.postAppearance}`)
            .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch posts');
            }
            return response.json();
        }).then(posts => {
            this.handleFetched(posts);
        }).catch(error => {
            console.error(error);
            this.noPostsFound();
        });
    }
    handleFetched(posts) {
        if (!posts || typeof posts !== 'string') {
            this.noPostsFound();
            return;
        }
        this.removePreloaders();
        this.renderContainer.innerHTML = posts;
    }
    removePreloaders() {
        this.preLoaders.forEach((preloader) => {
            preloader.remove();
        });
    }
    noPostsFound() {
        this.noPostsNotice.classList.remove(this.displayNoneClass);
        this.removePreloaders();
    }
    getFilteredPostIds() {
        let filteredPostIds = [];
        for (const likedPost of Object.keys(this.postIds)) {
            if (this.postTypesToShow.includes(this.postIds[likedPost])) {
                filteredPostIds.push(likedPost);
            }
        }
        return filteredPostIds;
    }
}
exports.default = LikeModule;
