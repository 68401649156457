"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class UserStorage {
    constructor(wpApiSettings, userId, likedPostsMeta) {
        this.wpApiSettings = wpApiSettings;
        this.userId = userId;
        this.likedPosts = {};
        this.userEndpoint = '';
        this.userEndpoint = `${this.wpApiSettings.root}wp/v2/users/${this.userId}`;
        this.likedPosts = likedPostsMeta;
    }
    get() {
        return this.likedPosts;
    }
    set(postId, postType) {
        var _a;
        let runtimeLikedPosts = this.get();
        if (runtimeLikedPosts[postId]) {
            delete runtimeLikedPosts[postId];
        }
        else {
            runtimeLikedPosts[postId] = postType;
        }
        fetch(this.userEndpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-NONCE': (_a = wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.nonce) !== null && _a !== void 0 ? _a : '',
            },
            body: JSON.stringify({
                meta: {
                    likedPosts: runtimeLikedPosts
                }
            }),
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Failed to update user data');
            }
            else {
                return response.json();
            }
        })
            .catch(error => {
            console.error('Error:', error);
        });
    }
}
exports.default = UserStorage;
