"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeLikedPosts = decodeLikedPosts;
function decodeLikedPosts(encodedLikedPosts) {
    const decodedLikedPosts = atob(encodedLikedPosts);
    const compactLikedPosts = JSON.parse(decodedLikedPosts);
    let likedPostsStructuredObject = {};
    for (const postType of Object.keys(compactLikedPosts)) {
        if (Array.isArray(compactLikedPosts[postType])) {
            compactLikedPosts[postType].forEach(id => {
                likedPostsStructuredObject[id] = postType;
            });
        }
    }
    return likedPostsStructuredObject;
}
