"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Like {
    constructor(likeStorage) {
        this.likeStorage = likeStorage;
        this.likeIconSelector = '.like-icon';
        this.handleLike();
        this.observe();
    }
    handleLike() {
        const likeButtons = [...document.querySelectorAll('[data-like-icon]')];
        this.setLiked();
        likeButtons && this.setListeners(likeButtons);
    }
    setListeners(likeButtons) {
        likeButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                var _a, _b;
                e.preventDefault();
                e.stopPropagation();
                const postId = (_a = button.getAttribute('data-post-id')) !== null && _a !== void 0 ? _a : '';
                const postType = (_b = button.getAttribute('data-post-type')) !== null && _b !== void 0 ? _b : '';
                this.likeStorage.set(postId, postType);
                this.toggleLiked(postId);
                window.dispatchEvent(this.likedPostsUpdatedEvent());
            });
            button.setAttribute('data-js-has-click', '');
        });
    }
    toggleLiked(postId) {
        const icons = [...document.querySelectorAll(`[data-post-id="${postId}"]`)];
        icons.forEach((icon) => {
            icon.classList.toggle('material-symbols--filled');
        });
    }
    setLiked() {
        const likedPosts = this.likeStorage.get();
        Object.keys(likedPosts).forEach((postId) => {
            const icons = [...document.querySelectorAll(`[data-post-id="${postId}"]`)];
            icons.forEach((icon) => {
                icon.classList.add('material-symbols--filled');
            });
        });
    }
    observe() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' &&
                    mutation.addedNodes.length > 0) {
                    let buttons = [];
                    [...mutation.addedNodes].forEach((node) => {
                        if (node.nodeType === Node.ELEMENT_NODE &&
                            node.querySelector(this.likeIconSelector)) {
                            node.querySelectorAll(`${this.likeIconSelector}:not([data-js-has-click])`).forEach((button) => {
                                buttons.push(button);
                            });
                            this.setLiked();
                        }
                    });
                    this.setListeners(buttons);
                }
            });
        });
        observer.observe(document.body, { childList: true, subtree: true, attributes: false });
    }
    likedPostsUpdatedEvent() {
        return new CustomEvent('likedPostsLengthUpdated', {});
    }
}
exports.default = Like;
