"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LocalStorage {
    constructor() {
        this.localeStorageKey = 'liked-posts-v2';
    }
    get() {
        const likedPostsJson = localStorage.getItem(this.localeStorageKey);
        return likedPostsJson ? JSON.parse(likedPostsJson) : {};
    }
    set(postId, postType) {
        let likedPostsIds = this.get();
        if (likedPostsIds[postId]) {
            delete likedPostsIds[postId];
        }
        else {
            likedPostsIds[postId] = postType;
        }
        localStorage.setItem(this.localeStorageKey, JSON.stringify(likedPostsIds));
    }
}
exports.default = LocalStorage;
