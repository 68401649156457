"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Shared {
    constructor(renderContainer, urlParams, title, excerpt) {
        this.renderContainer = renderContainer;
        this.urlParams = urlParams;
        this.title = title;
        this.excerpt = excerpt;
        this.displayNoneClass = 'u-display--none';
        this.nameParamKey = 'liked-name';
        this.excerptParamKey = 'liked-excerpt';
        this.addSharedData();
    }
    addSharedData() {
        let listName = this.urlParams.get(this.nameParamKey);
        let listExcerpt = this.urlParams.get(this.excerptParamKey);
        if (this.title && listName) {
            this.setSharedValue(this.title, listName);
        }
        if (this.excerpt && listExcerpt) {
            this.setSharedValue(this.excerpt, listExcerpt);
        }
    }
    setSharedValue(element, value) {
        element.textContent = this.sanitizeUrlParams(value);
        element.classList.remove(this.displayNoneClass);
    }
    sanitizeUrlParams(encodedString) {
        let string = atob(encodedString);
        string = string.replace(/(<([^>]+)>)/gi, '');
        return string;
    }
}
exports.default = Shared;
